import {
    cssVal,
    getDataByCodePostal, getDataBySiren,
    hidden,
    newInput, texteForCivilite,
    texteForNatureActe, tryToDate,
    visible
} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const locationGerances = {
    title: "Location Gérance",
    texte: "{{acteGroup}}, {{bailleurGroup}}, a donné en location gérance à {{locataireGerantGroup}} un fonds de commerce de {{fondsLoueGroup.activiteStr}} sis et exploité au {{fondsLoueGroup.adresse}} {{fondsLoueGroup.codePostal}} {{fondsLoueGroup.ville}}, objet d'une immatriculation au RCS {{fondsLoueGroup.lieuImmatriculation}} sous le n°{{fondsLoueGroup.rcs}}, pour une durée de {{fondsLoueGroup.duree}} {{fondsLoueGroup.typeDuree}} à compter du {{fondsLoueGroup.dateDebut}}, {{fondsLoueGroup.typeRenouvellement}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "bailleurGroup":
                if(varText['bailleurGroup.typePersonne'] === 'morale') {
                    tmpText = `la société <strong>${cssVal(varText['bailleurGroup.societeDenomination'])}</strong>, ${cssVal(varText['bailleurGroup.societeFormeJuridique'])} au capital de ${cssVal(varText['bailleurGroup.societeCapital'])} €, siège au ${cssVal(varText['bailleurGroup.societeAdresse'])}, ${cssVal(varText['bailleurGroup.societeAdresse2'])} ${cssVal(varText['bailleurGroup.societeCodePostal'])} ${cssVal(varText['bailleurGroup.societeVille'])}, Rcs ${cssVal(varText['bailleurGroup.societeRcsVille'])} ${cssVal(varText['bailleurGroup.societeRcs'])}`;
                } else {
                    tmpText = `<strong>${texteForCivilite(varText['bailleurGroup.civilite'])} ${cssVal(varText['bailleurGroup.prenom'])} ${cssVal(varText['bailleurGroup.nom'])}</strong> ${cssVal(varText['bailleurGroup.societeAdresse'])}, ${cssVal(varText['bailleurGroup.societeAdresse2'])} ${cssVal(varText['bailleurGroup.societeCodePostal'])} ${cssVal(varText['bailleurGroup.societeVille'])}`;
                }
                break;
            case "locataireGerantGroup":
                if(varText['locataireGerantGroup.typePersonne'] === 'morale') {
                    tmpText = `la société <strong>${cssVal(varText['locataireGerantGroup.denomination'])}</strong>, ${cssVal(varText['locataireGerantGroup.formesSocietes'])} au capital de ${cssVal(varText['locataireGerantGroup.montantCapital'])} €, siège :  ${cssVal(varText['locataireGerantGroup.adresse'])}, ${cssVal(varText['locataireGerantGroup.adresseSuite'])} ${cssVal(varText['locataireGerantGroup.codePostal'])} ${cssVal(varText['locataireGerantGroup.ville'])}, Rcs ${cssVal(varText['locataireGerantGroup.lieuImmatriculation'])} ${cssVal(varText['locataireGerantGroup.rcs'])}`;
                } else {
                    tmpText = `<strong>${texteForCivilite(varText['locataireGerantGroup.civilite'])} ${cssVal(varText['locataireGerantGroup.prenom'])} ${cssVal(varText['locataireGerantGroup.nom'])}</strong> ${cssVal(varText['locataireGerantGroup.adresse'])}, ${cssVal(varText['locataireGerantGroup.adresseSuite'])} ${cssVal(varText['locataireGerantGroup.codePostal'])} ${cssVal(varText['locataireGerantGroup.ville'])}`;
                }
                break;
            case "acteGroup":
                tmpText = texteForNatureActe(varText, 'acteGroup');
                break;
            case "fondsLoueGroup.dateDebut":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        bailleurGroup: [{}],
        locataireGerantGroup: [{}],
        fondsLoueGroup: [{}],
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Acte",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            locationGerances.schema[0].children[3] = val === "acte authentique" ? visible(locationGerances.schema[0].children[3]) : hidden(locationGerances.schema[0].children[3]);
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "bailleurGroup",
            label: "Bailleur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            locationGerances.schema[1].children[1] = val === "physique" ? visible(locationGerances.schema[1].children[1]) : hidden(locationGerances.schema[1].children[1]);
                            locationGerances.schema[1].children[2] = val === "physique" ? visible(locationGerances.schema[1].children[2]) : hidden(locationGerances.schema[1].children[2]);
                            locationGerances.schema[1].children[3] = val === "physique" ? visible(locationGerances.schema[1].children[3]) : hidden(locationGerances.schema[1].children[3]);

                            locationGerances.schema[1].children[4] = val === "morale" ? visible(locationGerances.schema[1].children[4]) : hidden(locationGerances.schema[1].children[4]);
                            locationGerances.schema[1].children[5] = val === "morale" ? visible(locationGerances.schema[1].children[5]) : hidden(locationGerances.schema[1].children[5]);
                            locationGerances.schema[1].children[6] = val === "morale" ? visible(locationGerances.schema[1].children[6]) : hidden(locationGerances.schema[1].children[6]);
                            locationGerances.schema[1].children[7] = val === "morale" ? visible(locationGerances.schema[1].children[7]) : hidden(locationGerances.schema[1].children[7]);
                            locationGerances.schema[1].children[8] = val === "morale" ? visible(locationGerances.schema[1].children[8]) : hidden(locationGerances.schema[1].children[8]);

                        }
                    }
                }),
                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {name: 'societeRcs',
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.model.bailleurGroup[0] = Object.assign(locationGerances.model.bailleurGroup[0], data.values.societe, data.values.siegeSocial);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            locationGerances.schema[1].children[12].options = dataCP.options;
                            locationGerances.model.bailleurGroup[0].societeVille = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'hidden'),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}, 'hidden'),
                newInput(def.formesSocietes, '_required', {name: 'societeFormeJuridique'}, 'hidden'),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}, 'hidden'),

                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.schema[1].children[12].options = data.options;
                            locationGerances.model.bailleurGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
            ]
        },
        {
            //2
            name: "locataireGerantGroup",
            label: "Locataire gérant",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typePersonne, '_required', {
                    on: {
                        input: (val) => {
                            locationGerances.schema[2].children[1] = val === "physique" ? visible(locationGerances.schema[2].children[1]) : hidden(locationGerances.schema[2].children[1]);
                            locationGerances.schema[2].children[2] = val === "physique" ? visible(locationGerances.schema[2].children[2]) : hidden(locationGerances.schema[2].children[2]);
                            locationGerances.schema[2].children[3] = val === "physique" ? visible(locationGerances.schema[2].children[3]) : hidden(locationGerances.schema[2].children[3]);

                            locationGerances.schema[2].children[4] = val === "morale" ? visible(locationGerances.schema[2].children[4]) : hidden(locationGerances.schema[2].children[4]);
                            locationGerances.schema[2].children[5] = val === "morale" ? visible(locationGerances.schema[2].children[5]) : hidden(locationGerances.schema[2].children[5]);
                            locationGerances.schema[2].children[6] = val === "morale" ? visible(locationGerances.schema[2].children[6]) : hidden(locationGerances.schema[2].children[6]);
                            locationGerances.schema[2].children[7] = val === "morale" ? visible(locationGerances.schema[2].children[7]) : hidden(locationGerances.schema[2].children[7]);
                            locationGerances.schema[2].children[8] = val === "morale" ? visible(locationGerances.schema[2].children[8]) : hidden(locationGerances.schema[2].children[8]);

                        }
                    }
                }),
                newInput(def.civilite, '_required'),//
                newInput(def.nom, '_required'),//
                newInput(def.prenom, '_required'),//

                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.model.locataireGerantGroup[0] = Object.assign(locationGerances.model.locataireGerantGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            locationGerances.schema[2].children[12].options = dataCP.options;
                            locationGerances.model.locataireGerantGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.lieuImmatriculation, '_required', {}, 'hidden'),
                newInput(def.denomination, '_required', {}, 'hidden'),
                newInput(def.formesSocietes, '_required', {}, 'hidden'),
                newInput(def.montantCapital, '_required', {}, 'hidden'),

                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.schema[2].children[12].options = data.options;
                            locationGerances.model.locataireGerantGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //3
            name: "fondsLoueGroup",
            label: "Fonds loué",
            type: "group",
            value: [{}],
            children: [
                newInput(def.rcs, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value.replaceAll(' ', '');
                            if (val.length !== 9) {
                                return false;
                            }

                            const data = await getDataBySiren(val);
                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.model.fondsLoueGroup[0] = Object.assign(locationGerances.model.fondsLoueGroup[0], data.values.all);

                            const dataCP = await getDataByCodePostal(data.values.siegeSocial.societeCodePostal, data.values.siegeSocial.societeVille);
                            locationGerances.schema[3].children[5].options = dataCP.options;
                            locationGerances.model.fondsLoueGroup[0].ville = dataCP.ville_slug;
                        }
                    }
                }),
                newInput(def.lieuImmatriculation, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            locationGerances.schema[3].children[5].options = data.options;
                            locationGerances.model.fondsLoueGroup[0].ville = data.ville_slug;
                            locationGerances.model.fondsLoueGroup[0].lieuImmatriculation = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
                newInput(def.activiteStr, '_required'),
                newInput(def.dateDebut, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeDuree, '_required'),
                newInput(def.typeRenouvellement, '_required'),
            ]
        },
    ]
}